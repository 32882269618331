export default [
  "479",//Rotom
  "486",//Regigigas
  "489",//Phione
  "490",//Manaphy
  "491",//Darkrai
  "492",//Shaymin
  "493",//Arceus

  "494",//Victini
  "517",//Munna
  "518",//Musharna
  "546",//Cottonee
  "547",//Whimsicott
  "548",//Liligant
  "551",//Sandile
  "552",//Krokorok
  "553",//Krookodile
  "554",//Darumaka
  "555",//Darmanitan
  "559",//Scraggy
  "560",//Scrafty
  "570",//Zorua
  "571",//Zoroark
  "574",//Gothita
  "575",//Gothorita
  "576",//Gothitelle
  "577",//Solosis
  "578",//Duosion
  "579",//Reuniclus
  "580",//Ducklett
  "581",//Swanna
  "582",//Vanillite
  "583",//Vanillish
  "584",//Vanilluxe
  "585",//Deerling
  "586",//Sawsbuck
  "587",//Emolga
  "592",//Frillish
  "593",//Jellicent
  "602",//Tynamo
  "603",//Eelektrik
  "604",//Eelektross
  "605",//Elgyem
  "606",//Beheeyem
  "618",//Stunfisk
  "619",//Mienfoo
  "620",//Mienshao
  "621",//Druddigon
  "624",//Pawniard
  "625",//Bisharp
  "626",//Bouffalant
  "627",//Rufflet
  "628",//Braviary
  "629",//Vullaby
  "630",//Mandibuzz
  "636",//Larvesta
  "637",//Volcarona
  "643",//Reshiram
  "644",//Zekrom
  "646",//Kyurem
  "647",//Keldeo
  "648",//Meloetta
  "649",//Genesect
];
